import React from 'react';
import Benefit1 from '../assets/images/OurBenefits/benefit1.jpg';
import Benefit2 from '../assets/images/OurBenefits/benefit2.jpg';
import Benefit3 from '../assets/images/OurBenefits/benefit3.jpg';
import Benefit4 from '../assets/images/OurBenefits/benefit4.jpg';
import Benefit5 from '../assets/images/OurBenefits/benefit5.jpg';

function OurBenefits() {
    return (
        <div className="ourBenefits color-myprimary">
            <div className="container-xl pt-5">
                <div className="row">
                    <div className="col-12 mb-5">
                        <h1 className='sectionHeading text-center'>OUR BENEFITS</h1>
                        <p className="text-justify">Being a new-age company, we believe in serving our employees. We do this by nurturing an environment where they can thrive and work on their very own innovative ideas, offering a wide range of benefits, and constantly finding new ways to keep our talent pool relevant, valuable, and market competitive.</p>
                    </div>
                    <>
                        <div className="col-md-7 mb-5">
                            <h3>Fulfilling Work. Fast-Paced Career Paths</h3>
                            <p><strong>7 Layers to the top</strong></p>
                            <p>Unlike traditional companies that have 12 and more steps in their career ladder, we have just 7. You have a clearer path to rise through the ranks.</p>
                            <p><strong>Competitive Packages</strong></p>
                            <p>Our compensation packages are highly competitive in comparison to the market with a range of benefits and a yearly bonus.</p>
                        </div>
                        <div className="col-md-5 mb-5">
                            <img src={Benefit1} alt="Fulfilling Work" title='Fulfilling Work' className='img-fluid' />
                        </div>
                    </>
                    <>
                        <div className="col-md-5 mb-5">
                            <img src={Benefit2} alt="Wellbeing And Continued Support" title='Wellbeing And Continued Support' className='img-fluid' />
                        </div>
                        <div className="col-md-7 mb-5">
                            <h3>Wellbeing & Continued Support</h3>
                            <p><strong>Employee Wellbeing Program</strong></p>
                            <p>As one of the top tech companies in Pakistan, our compensation packages are highly competitive, in terms of salary, performance rewards, and benefits.</p>
                            <p><strong>Medical Coverage</strong></p>
                            <p>Employees and their immediate families are covered for a wide range of emergencies and incidents, including maternity coverage, In-patient & Day Care (IPD) and Out-patient & Day Care (OPD).</p>
                        </div>
                    </>
                    <>
                        <div className="col-md-7 mb-5">
                            <h3>Learning & Development</h3>
                            <p><strong>7 Layers to the top</strong></p>
                            <p>I.T.S makes it easy for people to attend learning events within and outside I.T.S as interacting with people who are doing interesting work revitalizes people’s thought to perform better.</p>
                            <p><strong>Self-Directed Learning</strong></p>
                            <p>Self-Directed learning is an integral part of I.T.S. We support self-learners and DIY enthusiasts by extensively planning time for learning as well as providing the resources and help they need.</p>
                            <p><strong>Structured Trainings</strong></p>
                            <p>Structured training is scheduled on a wide range of topics throughout the year to educate and develop the skill sets and mindsets of our employees.</p>
                            <p><strong>Online Courses</strong></p>
                            <p>With a wide range of learning sessions that we offer to train our employees, we also impart different gadgets training sessions to expose our employees with particular features of the products.</p>
                        </div>
                        <div className="col-md-5 mb-5">
                            <img src={Benefit3} alt="Learning And Development" title='Learning And Development' className='img-fluid' />
                        </div>
                    </>
                    <>
                        <div className="col-md-5 mb-5">
                            <img src={Benefit4} alt="Perks And Rewards" title='Perks And Rewards' className='img-fluid' />
                        </div>
                        <div className="col-md-7 mb-5">
                            <h3>Perks & Rewards</h3>
                            <p><strong>Performance Bonuses</strong></p>
                            <p>Performance is not just appreciated, but fully rewarded at I.T.S.</p>
                            <p><strong>EOBI</strong></p>
                            <p>Employees and their immediate families are covered for a wide range of emergencies and incidents, including maternity coverage, In-patient & Day Care (IPD) and Out-patient & Day Care (OPD).</p>
                            <p><strong>Interest Free Loans</strong></p>
                            <p>As part of our financial wellness, we offer Interest Free Loans with simple payment options to all employees.</p>
                        </div>
                    </>
                    <>
                        <div className="col-md-7 mb-5">
                            <h3>Time Off/Flexibility</h3>
                            <p><strong>Paternity & Maternity Leaves</strong></p>
                            <p>I.T.S celebrates and supports parenthood.</p>
                            <p><strong>Wedding Leaves</strong></p>
                            <p>Employees are entitled to take time off for their weddings. This is in addition to their PTOs.</p>
                            <p><strong>Pilgrimage Leaves</strong></p>
                            <p>Employees are entitled to avail up to 30 days of paid leaves for performing pilgrimage.</p>
                            <p><strong>Work from Home</strong></p>
                            <p>To accommodate family emergencies and other special cases, employees are allowed to work from home.</p>
                        </div>
                        <div className="col-md-5 mb-5">
                            <img src={Benefit5} alt="Time Off/Flexibility" title='Time Off/Flexibility' className='img-fluid' />
                        </div>
                    </>
                </div>
            </div>
        </div>
    )
}

export default OurBenefits;