import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faPeopleGroup, faHandshakeAngle, faPeopleArrowsLeftRight, faMagnifyingGlass, faHandHoldingHeart } from '@fortawesome/free-solid-svg-icons';

function CoreValues() {
    const data = [
        { heading: 'Proactivity', discription: 'Pay attention. Take charge and lead whenever and wherever an opportunity presents itself.', icon: faLightbulb, animation: "fade-down-right" },
        { heading: 'Teamwork', discription: 'Be comfortable working with others to reach a goal. Great things are always achieved together.', icon: faPeopleGroup, animation: "fade-down" },
        { heading: 'Value Creation', discription: 'Make work better for all. Contribute relentlessly. Be curious and bring joy and optimism to work.', icon: faHandshakeAngle, animation: "fade-down-left" },
        { heading: 'Adaptation', discription: 'Change is necessary for growth. Be open to adapting and embracing change whole-heartedly instead of fighting it.', icon: faPeopleArrowsLeftRight, animation: "fade-up-right" },
        { heading: 'Transparency', discription: 'Share ideas and opinions without fearing criticism or ridicule. Wild and weird ideas are appreciated.', icon: faMagnifyingGlass, animation: "fade-up" },
        { heading: 'Empathy', discription: 'Understand and respect others for their uniqueness, even when you have a difference of opinions.', icon: faHandHoldingHeart, animation: "fade-up-left" },
    ]
    return (
        <div className="coreValues color-myprimary">
            <div className="container-xl pt-5">
                <div className="row">
                    <div className="col-12 mb-5 text-center">
                        <h1 className='sectionHeading'>CORE VALUES</h1>
                        <p className='fw-bold'>These six core values guide every decision we make at I.T.S</p>
                    </div>
                    {data.map((item, index) => {
                        return <div key={index} className="col-md-4 mb-5 p-2 p-md-4" data-aos={item.animation} data-aos-duration="1000">
                            <div className="content">
                                <div className="icon-container text-center mb-3">
                                    <FontAwesomeIcon className='coreValueIcon' icon={item.icon} />
                                </div>
                                <h3 className='text-center'>{item.heading}</h3>
                                <p className='text-justify'>{item.discription}</p>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default CoreValues;