import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import Service1 from '../assets/images/Services/service1.png';
import Service2 from '../assets/images/Services/service2.png';
import Service3 from '../assets/images/Services/service3.png';
import Service4 from '../assets/images/Services/photoshop-illustrator.png';
import QuickServicesImage from '../assets/images/quickServies.jpg'

function QuickServices() {
    const data = [
        { image: Service1, heading: 'Website design and development', list: ['Sitemap', 'Data Migration', 'UX/UI'], animation: 'fade-right' },
        { image: Service2, heading: 'Publishing services', list: ['English language editing', 'Types editing', 'Plagiarism checks', 'English language checks'], animation: 'fade-up' },
        { image: Service3, heading: 'Data conversions', list: ['Kindle', 'XML', 'HTML'], animation: 'fade-down' },
        { image: Service4, heading: 'Illustration and Figures', list: ['Figure improvements', 'Figure enhancement services', 'Graphics'], animation: 'fade-left' },
    ];
    return (
        <div className="quickServices pt-5 color-myprimary">
            <div className="container-xl">
                <h1 className="sectionHeading text-center">Quick Services</h1>
                <div className="row pt-5">
                    {data.map((item, index) => {
                        return <div key={index} className="col-md-3 mb-5" data-aos={item.animation} data-aos-duration="1000">
                            <div className="card card-rounded h-100">
                                <img src={item.image} alt={item.heading} title={item.heading} />
                                <div className="card-body px-4 py-5">
                                    <h4 className='service-type mb-4'>{item.heading}</h4>
                                    <ul className='list-style-none'>
                                        {item.list.map((v, i) => {
                                            return <li className='service-list' key={i}><CheckIcon className='list-check' /> {v}</li>
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
                <div className="row">
                    <div className="col-md-6 mb-5 align-items-center d-flex" data-aos="fade-right" data-aos-animation="3000">
                        <div>
                            <h1>Quick Services</h1>
                            <p className="text-justify">With our Quick plans, we offer a minimum turnaround time. Immediate application process, instant services provided, in a minimal cost. These one-time projects can be availed at any time for your ongoing projects or one-time projects. Just click on Get a Quote!</p>
                        </div>
                    </div>
                    <div className="col-md-6 mb-5" data-aos="fade-left" data-aos-animation="3000">
                        <img src={QuickServicesImage} alt="Quick Services" title="Quick Services" className='img-fluid' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuickServices;