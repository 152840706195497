import React from 'react';
import LifeStyle from '../assets/images/lifeStyle.png'

function ItsLifeStyle() {
    return (
        <div className="itsLifeStyle">
            <div className="container-xl pt-5 color-myprimary">
                <div className="row">
                    <div className="col-md-6 mb-5 p-2 py-md-5" data-aos="fade-right" data-aos-duration="1000">
                        <h1 className='text-center'>I.T.S Lifestyle</h1>
                        <p className="text-justify">
                            At I.T.S, you’re not just an employee, you’re part of a big vibrant family of leaders and experts, who challenge the norm and are on the top of their game. It’s these leaders who enable our staff to execute day in, day out, and have the leverage to experiment. Life at I.T.S is full of fun, adventure, laughter, and a non-stop blast. It’s not just working, it’s a Lifestyle!
                        </p>
                    </div>
                    <div className="col-md-6 mb-5" data-aos="fade-left" data-aos-duration="1000">
                        <img src={LifeStyle} alt="its-life-style" title='Its Life Style' className='img-fluid' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ItsLifeStyle;