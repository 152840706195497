import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import ourDetailedServices from '../assets/images/ourDetailedServices.png';
import SoftwareSolutions from '../assets/images/Services/service4.png';
import EcommerceDevelopment from '../assets/images/Services/Ecommerce-development.png';
import PaymentSolution from '../assets/images/Services/service5.png';
import DataManagments from '../assets/images/Services/data-managments.png';
import CosultancyServices from '../assets/images/Services/cosultancy-services.png';
import AccessControl from '../assets/images/Services/Access-control.png';
import DigitalServices from '../assets/images/Services/digital-services.png';
import QualityAssurance from '../assets/images/Services/Quality-assurance.png';
import Printing from '../assets/images/Services/printing.png';
import Security from '../assets/images/Services/service6.png';

function OurDetailedServices() {
    const data = [
        { image: Security, heading: 'Security', list: ['Eurekaselect', 'Access control systems'] },
        { image: PaymentSolution, heading: 'Payment Solutions', list: ['Payment links', 'Payment integration', 'PayPal links', 'PayPal integrations'] },
        { image: EcommerceDevelopment, heading: 'Ecommerce Development', list: ['eCommerce Sales Portal', 'Application development', 'Payment solutions', 'Payment integration'] },
        { image: SoftwareSolutions, heading: 'Software Solutions', list: ['ERP solutions', 'Lab management', 'Conference Management System', 'API integration'] },
        { image: DataManagments, heading: 'Data Management', list: ['IMS (Database Management) (Portfolio)'] },
        { image: CosultancyServices, heading: 'Consultancy Services', list: ['Indexing Agency Consultancy services'] },
        { image: AccessControl, heading: 'Access Controls', list: ['Subscription Access Modules of Content behind paywalls'] },
        { image: DigitalServices, heading: 'Digital Services', list: ['Digital Marketing', 'Email Marketing', 'Social Media Marketing'] },
        { image: QualityAssurance, heading: 'Quality Assurance', list: [] },
        { image: Printing, heading: 'Printing services', list: ['Eureka express'] }
    ];
    return (
        <div className="quickServices pt-5 color-myprimary">
            <div className="container-xl">
                <h1 className="sectionHeading text-center">Our Detailed services</h1>
                <div className="row pt-5 justify-content-center">
                    {data.map((item, index) => {
                        return <div key={index} className="col-md-3 mb-5" data-aos="fade-up" data-aos-duration="1000">
                            <div className="card card-rounded h-100">
                                <img src={item.image} alt={item.heading} title={item.heading} />
                                <div className="card-body px-4 py-5">
                                    <h4 className='service-type mb-4'>{item.heading}</h4>
                                    <ul className='list-style-none'>
                                        {item.list.map((v, i) => {
                                            return <li className='service-list' key={i}><CheckIcon className='list-check' /> {v}</li>
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
                <div className="row">
                    <div className="col-md-6 mb-5 align-items-center d-flex">
                        <div>
                            <h1>Detailed services</h1>
                            <p className="text-justify">With our detailed plans, an extensive plan is provided. A cost and timeline for the project is quoted before the project starts. Most of our long-term customers avail of our specialized services Get in touch with our experts and plan the scope of work according to your requirements!</p>
                        </div>
                    </div>
                    <div className="col-md-6 mb-5">
                        <img src={ourDetailedServices} alt="Quick Services" title="Quick Services" className='img-fluid' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurDetailedServices;