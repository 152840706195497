import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faHandsWash, faPencilAlt, faBullhorn, faScrewdriverWrench, faChartSimple, faRocket } from '@fortawesome/free-solid-svg-icons';

function WorkingProcess() {
    const data = [
        { type: 'Goal Setting', discription: 'Success begins with keeping the ‘end’ in mind. Determining how much will be spent and what results are expected by developing revenue and establishing lead generation metrics.', icon: faCartPlus, value: 1, animation: 'fade-right' },
        { type: 'Audience', discription: 'It’s important to have an understanding of your targeted market and your “ideal clients” and plan your way accordingly.', icon: faHandsWash, value: 2, animation: 'fade-up' },
        { type: 'Content', discription: 'To influence, you should have good matter. In order to impress your clients, your content should be powerful and precise.', icon: faPencilAlt, value: 3, animation: 'fade-down' },
        { type: 'Inbound Marketing', discription: 'Inbound marketing adds a value to the probable clients with content that draws attention to your business. Increasing social media outreach and publication of podcasts and blogs about your business can multiply your client count.', icon: faBullhorn, value: 4, animation: 'fade-left' },
        { type: 'Analyze, Refine, and Improve', discription: 'Analyzing real-time data plays a role in improving your reach over time as it refines your business.', icon: faScrewdriverWrench, value: 5, animation: 'fade-up-right' },
        { type: 'Lead Conversation Optimization', discription: 'Optimization with search engines like Google maximizes the value of your sales opportunity.', icon: faChartSimple, value: 6, animation: 'fade-up' },
        { type: 'Outbound Marketing', discription: 'Selecting the most appropriate outbound marketing tactics can contribute to your demand in the market.', icon: faRocket, value: 7, animation: 'fade-up-left' }
    ];
    return (
        <div className="working-process bg-white pt-5">
            <div className="container-xl">
                <div className="col-12 col-md-9 m-auto mb-5 text-center">
                    <h3 className='heading mb-3'>Working Process</h3>
                    <p>We here at I.T.S never compromise over quality. With our significant work ethic
                        and approachable plans, I.T.S doesn’t look back at satisfying clients.</p>
                </div>
                <div className="row justify-content-around">
                    {data.map((item, index) => {
                        return <div key={index} className="col-md-3 mb-5" data-aos={item.animation} data-aos-duration="1000">
                            <div className="working-container text-center">
                                <div className="icon-container flex-center mb-4">
                                    <FontAwesomeIcon className='fs-50' icon={item.icon} />
                                    <div className="icon-value flex-center">
                                        <span>{item.value}</span>
                                    </div>
                                </div>
                                <div>
                                    <h3 className='work-type'>{item.type}</h3>
                                    <p className='work-discription'>{item.discription}</p>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default WorkingProcess;