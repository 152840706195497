import React from 'react';
import Creative from '../assets/images/top-about/creative.png';
import BestSupport from '../assets/images/top-about/Best-Support.png';
import WebDevelopment from '../assets/images/top-about/Web-development.png';

function TopAbout() {
    const state = [
        { type: 'Creative', discription: 'Here at I.T.S, we showcase custom-developed technology solutions with innovation and originality.', image: Creative },
        { type: 'Best Support', discription: 'We believe in building an affable relationship with our patrons as we provide them with quality services.', image: BestSupport },
        { type: 'Development', discription: 'I.T.S aims to ensure your growth and our devoted team strives to do so.', image: WebDevelopment },
    ];
    return (
        <div className="topAbout">
            <div className="container-xl pt-5">
                <div className="row">
                    {state.map((item, index) => {
                        return <div key={index} className="col-md-4 mb-5" data-aos="fade-up"
                            data-aos-duration="1000">
                            <div className="text-center">
                                <div className=" mb-3">
                                    <img src={item.image} alt={item.type} title={item.type} />
                                </div>
                                <h3 className='type'>{item.type}</h3>
                                <p className='discription'>{item.discription}</p>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default TopAbout;