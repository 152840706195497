import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

// import images
import Drugdiscovery from '../assets/images/our-partners/Drugs-Discovery.png';
import EK from '../assets/images/our-partners/EK.png';
import EKScience from '../assets/images/our-partners/EK-Science.png';
import Eureko from '../assets/images/our-partners/Eureko.png';
import Global from '../assets/images/our-partners/Global.png';
import Praham from '../assets/images/our-partners/Praham.png';
import Texitech from '../assets/images/our-partners/TExitech.png';
import Xolva from '../assets/images/our-partners/Xolva.png';

function OurPartners() {
    const multicarousel = [
        { img: Drugdiscovery, alt: 'Drugdiscovery' },
        { img: EK, alt: 'EK' },
        { img: EKScience, alt: 'EKScience' },
        { img: Eureko, alt: 'Eureka' },
        { img: Global, alt: 'Global' },
        { img: Praham, alt: 'Praham' },
        { img: Texitech, alt: 'Texitech' },
        { img: Xolva, alt: 'Xolva' },
    ]
    const responsive = {
        desktop: {
            breakpoint: { max: 4000, min: 992 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 992, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <div className="py-5 our_partners">
            <div className="container-xl">
                <div className="row">
                    <div className="col-12 mb-3 text-center">
                        <h5 className='color-myprimary'>Clients</h5>
                        <h1 className="section-heading color-myprimary">Our Partners</h1>
                    </div>
                    <div className="col-12">
                        <Carousel
                            responsive={responsive}
                            showDots={true}
                        >
                            {multicarousel.map((item, index) => (
                                <div key={index} className="text-center">
                                    <img src={item.img} alt={item.alt} className="img-fluid" />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurPartners;