import React, { useState } from 'react';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';

// import image
import Eurekaselect from '../assets/images/Gallery/Eurekaselect.jpg';
import EurekaScience from '../assets/images/Gallery/Eureka-Science.jpg';
import EurukaSelect from '../assets/images/Gallery/Euruka-select.jpg';
import IOIEE from '../assets/images/Gallery/Institute-of-Industrial-Electronics-Engineering.jpg';
import GBC from '../assets/images/Gallery/Global-Biotechnology-Congress.jpg';
import JPMC from '../assets/images/Gallery/JPMC.jpg';
import PCSIR from '../assets/images/Gallery/PCSIR.jpg';
import DDTC from '../assets/images/Gallery/DDTC.jpg';

function ProjectGallery() {
    const [index, setIndex] = useState(0);
    const [isOpen, setisOpen] = useState(false);
    const images = [Eurekaselect, EurekaScience, EurukaSelect, IOIEE, GBC, JPMC, PCSIR, DDTC];
    return (
        <>
            {isOpen ?
                <Lightbox
                    mainSrc={images[index]}
                    nextSrc={images[(index + 1) % images.length]}
                    prevSrc={images[(index + images.length - 1) % images.length]}
                    onCloseRequest={() => setisOpen(false)}
                    onMovePrevRequest={() =>
                        setIndex((index + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() =>
                        setIndex((index + 1) % images.length)
                    }
                />
                : null
            }
            <div className="projectGallery pt-5">
                <div className="container-xl">
                    <h1 className="sectionHeading text-center color-myprimary mb-5">Our Projects</h1>
                    <div className="row">
                        {images.map((item, key) => {
                            return <div role='button' onClick={() => { setIndex(key); setisOpen(true) }} key={key} className="col-md-3 mb-3">
                                <img src={item} alt="Gallery" className='img-fluid' />
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProjectGallery;