import React, { useEffect } from 'react';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
function GotoTop() {
    useEffect(() => {
        var gotobtn = document.getElementById("myBtn");
        window.onscroll = function () { scrollFunction() };

        function scrollFunction() {
            if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
                gotobtn.style.display = "flex";
            } else {
                gotobtn.style.display = "none";
            }
        }

        // When the user clicks on the button, scroll to the top of the document
    }, []);
    const topFunction = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    return (
        <div role="button" onClick={() => topFunction()} className="gotobtn flex-center" id="myBtn">
            <KeyboardArrowUpIcon className='topbtn' />
        </div>
    )
}

export default GotoTop;