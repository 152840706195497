import React, { useState, useEffect } from 'react';
import Loader from '../Components/Loader';
import ChangePageTitle from '../Components/ChangePageTitle';
import GotoTop from '../Components/GotoTop';
import TopHeader from '../Components/TopHeader';
import MainMenu from '../Components/MainMenu';
import ProjectGallery from '../Components/ProjectGallery';
import Footer from '../Components/Footer';

function Projects() {
  const [isLoader, setisLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setisLoader(false);
    }, 1000);
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      {isLoader ? <Loader /> : null}
      <ChangePageTitle pageTitle="Information Technology Services (I.T.S) :: Projects" />
      <GotoTop />
      <TopHeader />
      <MainMenu />
      <ProjectGallery />
      <Footer />
    </>
  )
}

export default Projects;