import React from 'react'
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

function CareerIts() {
    return (
        <div className="careerIts">
            <div className="container-xl py-5">
                <div className="col-12 col-md-8 text-center color-myprimary m-auto">
                    <h1 className="sectionHeading">Career at I.T.S</h1>
                    <p className='text-justify'>Our team can assist you in transforming your business through latest tech capabilities to stay ahead of the curve.</p>
                </div>                
                <div className="border-top border-bottom text-center py-3">
                    <a className="btn btn-dark btn-lg px-5 mx-auto btn-careers" href="https://portal.xolva.com/careers/jobs" target='_blank' rel="noopener noreferrer">Job Openings <ChevronRightRoundedIcon className='icon' fontSize='Large' /></a>
                </div>
            </div>            
        </div>
    )
}

export default CareerIts;