import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

// import Pages All Pages
import Home from '../Pages/Home';
import AboutUs from '../Pages/AboutUs';
import Services from '../Pages/Services';
import Projects from '../Pages/Projects';
import Career from '../Pages/Career';
import ContactUs from '../Pages/ContactUs';
import NotFound from '../Pages/NotFound';

function AppRouter() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="about-us" element={<AboutUs />} />
                <Route path="services" element={<Services />} />
                <Route path="projects" element={<Projects />} />
                <Route path="career" element={<Career />} />
                <Route path="contact-us" element={<ContactUs />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}

export default AppRouter;