import React, { useState } from 'react';
import { Form } from "react-bootstrap";
import Button from '@material-ui/core/Button';

function ContactForm() {
    const [Name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [Message, setMessage] = useState('');
    const contactSubmit = (e) => {
        e.preventDefault();
        console.log('Name ==> ', Name);
        console.log('Email ==> ', Email);
        console.log('phone ==> ', phone);
        console.log('subject ==> ', subject);
        console.log('Message ==> ', Message);
        setName('');
        setEmail('');
        setPhone('');
        setSubject('');
        setMessage('');
    }
    return (
        <div className="p-2 p-md-0 contact-form">
            <form onSubmit={(e) => contactSubmit(e)}>
                <div className="mb-3">
                    <h1>Get in touch</h1>
                </div>
                <Form.Group className="mb-3">
                    <Form.Label>Your name</Form.Label>
                    <Form.Control type="text" value={Name} onChange={(text) => setName(text.target.value)} required />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Your email</Form.Label>
                    <Form.Control type="email" value={Email} onChange={(text) => setEmail(text.target.value)} required />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Phone No</Form.Label>
                    <Form.Control type="text" value={phone} onChange={(text) => setPhone(text.target.value)} required />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Subject</Form.Label>
                    <Form.Control type="text" value={subject} onChange={(text) => setSubject(text.target.value)} required />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Your message (optional)</Form.Label>
                    <Form.Control as="textarea" rows={5} value={Message} onChange={(text) => setMessage(text.target.value)} required />
                </Form.Group>
                <Button variant="contained" color="primary" className='py-2 px-4 btn-submit' type='submit' >Submit</Button>
            </form>
        </div>
    )
}

export default ContactForm;