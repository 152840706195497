import React from 'react';
import ChangePageTitle from '../Components/ChangePageTitle';
import GotoTop from '../Components/GotoTop';
import TopHeader from '../Components/TopHeader';
import MainMenu from '../Components/MainMenu';
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';

function NotFound() {
    return (
        <>
            <ChangePageTitle pageTitle="Information Technology Services (I.T.S) :: Not Found" />
            <GotoTop />
            <TopHeader />
            <MainMenu />
            <div className="main py-5">
                <div className="container-lg text-center py-5">
                    <h1 className="color-myprimary big-404">404</h1>
                    <h1 className='color-myprimary mb-4'>Component Not Found</h1>
                    <Link className='read-more' to='/'>Go to Home</Link>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default NotFound;