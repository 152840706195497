import React, { useState } from 'react';
import '../assets/css/NavBar.css'
import { Link } from 'react-router-dom';
import Logo from '../assets/images/ITS-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

function MainMenu() {
    const [mobilenav, setmobilenav] = useState(false);
    const path = window.location.pathname;
    return (
        <header id="header" className='bg-myprimary'>
            <div className="container-xl d-flex align-items-center">
                <Link to="/" className="logo me-auto"><img src={Logo} alt="logo" className="img-fluid" /></Link>
                <nav id="navbar" className={`navbar order-last order-lg-0 ${mobilenav ? 'navbar-mobile' : null}`}>
                    <ul>
                        <li><Link className={path === '/' ? 'active' : null} to="/">Home</Link></li>
                        <li><Link className={path === '/about-us' ? 'active' : null} to="/about-us">About Us</Link></li>
                        <li><Link className={path === '/services' ? 'active' : null} to="/services">Services</Link></li>
                        <li><Link className={path === '/projects' ? 'active' : null} to="/projects">Projects</Link></li>
                        <li><Link className={path === '/career' ? 'active' : null} to="/career">Career</Link></li>
                        <li><Link className={path === '/contact-us' ? 'active' : null} to="/contact-us">Contact Us</Link></li>
                    </ul>
                    <FontAwesomeIcon className='mobile-nav-toggle' icon={!mobilenav ? faBars : faCircleXmark} onClick={() => setmobilenav(!mobilenav)} />
                </nav>{/* .navbar */}
            </div>
        </header>
    )
}

export default MainMenu;