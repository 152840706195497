import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Slider1 from '../assets/images/Slider/Slider-1.png';
import Slider2 from '../assets/images/Slider/Slider-2.png';
import Slider3 from '../assets/images/Slider/Slider-3.png';
import Slider4 from '../assets/images/Slider/Slider-4.png';
import Slider5 from '../assets/images/Slider/Slider-5.png';
import Slider6 from '../assets/images/Slider/Slider-6.png';
import Slider7 from '../assets/images/Slider/Slider-7.png';

function GlimpseOfLife() {
    return (
        <div className="GlimpseOfLife p-3 text-white">
            <div className="container-xl">
                <div className="row">
                    <div className="col-md-4 flex-center py-5">
                        <div className='text-center' data-aos="flip-left" data-aos-duration="3000">
                            <h5>A Glimpse of</h5>
                            <h1>Life at I.T.S</h1>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <Carousel indicators={false} fade interval={3000}>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={Slider1}
                                    alt="First slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={Slider2}
                                    alt="Second slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={Slider3}
                                    alt="Third slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={Slider4}
                                    alt="Fourth slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={Slider5}
                                    alt="Fifth slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={Slider6}
                                    alt="Sixth slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={Slider7}
                                    alt="Seventh slide"
                                />
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GlimpseOfLife