import React from 'react';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';

function AboutVision() {
    return (
        <div className="container-xl">
            <div className="row">
                <div className="col-md-6 bg-myprimary p-5 shadow flex-center" data-aos="fade-right" data-aos-animation="4000">
                    <EmojiObjectsIcon className='bigIcon' />
                </div>
                <div className="col-md-6 color-myprimary p-5 shadow flex-center" data-aos="fade-left" data-aos-animation="4000">
                    <div className='text-center'>
                        <h1>Vision</h1>
                        <p className='text-justify'>Solve Impediments to Success by engineering innovative ideas to life.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutVision;