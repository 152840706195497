import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Laptop from '../assets/images/HeroSection/slider1-element.png'

function HeroSection() {
    return (
        <div className="heroSection">
            <Carousel fade indicators={false}>
                <Carousel.Item className='herobg1'>
                    <div className="container-lg">
                        <div className="row carouselCaption">
                            <div className="col-md-5 py-5"
                                data-aos="fade-right" data-aos-duration="3000">
                                <h2 className='heading mb-4'>WEBSITE DEVELOPMENT & MAINTENANCE</h2>
                                <p className='discription'>With I.T.S’s innovative designs and operational web solutions, web design and management is made easier and effective</p>
                            </div>
                            <div className="col-md-7" data-aos="fade-left"
                                data-aos-easing="ease-in-sine">
                                <img src={Laptop} alt="Laptop" className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item className='herobg2'>
                    <div className="container-lg">
                        <div className="row carouselCaption">
                            <div className="col-md-6 py-5">
                                <h2 className='heading mb-4'>SOFTWARE SOLUTIONS SERVICES</h2>
                                <p className='discription'>Manage your records efficiently with I.T.S’s easy to reach online software.</p>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item className='herobg3'>
                    <div className="container-lg">
                        <div className="row carouselCaption">
                            <div className="col-md-6 py-5">
                                <h2 className='heading mb-4'>DATA CENTER/PROFESSIONAL DATA MANAGEMENT</h2>
                                <p className='discription'>Business productivity is made achievable through our new technologies and services.</p>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default HeroSection;