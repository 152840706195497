import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import ContactForm from './ContactForm';
import LocationMap from './LocationMap';

function ContactUsComponent() {
    return (
        <div className="color-myprimary">
            <div className="container-xl pt-5">
                <div className="row">
                    <div className="col-12 text-center mb-5">
                        <h1>Contact Us</h1>
                    </div>
                    <div className="col-md-6 mb-5 text-center" data-aos="fade-right" data-aos-duration="1000">
                        <div className="contact-icon bg-myprimary m-auto mb-4 flex-center text-white">
                            <FontAwesomeIcon className='icon50' icon={faMapLocationDot} />
                        </div>
                        <h2>Location</h2>
                        <p className='text-justify'>C-32, Block -1(A), Information Technology Services Building, Gulistan-e-Johar, Karachi, Pakistan.</p>
                    </div>
                    <div className="col-md-6 mb-5 text-center" data-aos="fade-left" data-aos-duration="1000">
                        <div className="contact-icon bg-myprimary m-auto mb-4 flex-center text-white">
                            <FontAwesomeIcon className='icon50' icon={faPhone} />
                        </div>
                        <h2>Phone</h2>
                        <p>+92-3333977095</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5 mb-5">
                        <ContactForm />
                    </div>
                    <div className="col-md-7 mb-5">
                        <LocationMap />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUsComponent;