import React from 'react';
import AboutIts from '../assets/images/About-ITS.jpg';
import CheckIcon from '@material-ui/icons/Check';

function AboutITS() {
    return (
        <div className="aboutIts">
            <div className="container-xl pt-5">
                <div className="row align-items-center">
                    <div className="col-md-6" data-aos="fade-right" data-aos-animation="3000" >
                        <img src={AboutIts} alt="About-Us" title="About Us" className='img-fluid' />
                    </div>
                    <div className="col-md-6 p-4 p-md-5 color-myprimary" data-aos="fade-left" data-aos-animation="3000">
                        <h2 className='mb-3'>ABOUT I.T.S</h2>
                        <p className='text-justify'>I.T.S is a technology solutions company providing consulting and implementation services for resolving impediments in your business. I.T.S provides solutions and services with focus on lowering technology barriers for its clients in order to enhance their return on investment. Formed by seasoned technology and business professionals, I.T.S has the ability to provide the finest solutions that allow businesses to gain automation and control of their work processes and aim for their continuous.</p>
                        <ul className='list-style-none'>
                            <li><CheckIcon /> Cloud Services</li>
                            <li><CheckIcon /> Digital Solution</li>
                            <li><CheckIcon /> Proofreading</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutITS;