import React from 'react';

// import icons
import CallIcon from '@material-ui/icons/Call';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

function TopHeader() {
  return (
    <div className="topHeader bg-white color-myprimary py-2">
      <div className="container-xl">
        <div className="row">
          <div className='col-md-6 d-none d-md-block pt-2'>
            <span className='me-5'><CallIcon className='icon' /><a className='color-myprimary' href="tel:+92-3333977095">+92-3333977095</a></span>
            <span><AccessTimeIcon className='icon' />Mon - Fri: 9:00 - 6:00</span>
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-center justify-content-md-end">
              <div className="social-icon flex-center">
                <a href="https://www.facebook.com/pages/category/Science--Technology---Engineering/Information-Technology-Services-1693775174054647/"><FacebookIcon className='icon' /></a>
              </div>
              <div className="social-icon flex-center">
                <a href="https://pk.linkedin.com/company/information-technology-services-pk"><LinkedInIcon className='icon' /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopHeader;