import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import Service1 from '../assets/images/Services/service1.png';
import Service2 from '../assets/images/Services/service2.png';
import Service3 from '../assets/images/Services/service3.png';
import Service4 from '../assets/images/Services/service4.png';
import Service5 from '../assets/images/Services/service5.png';
import Service6 from '../assets/images/Services/service6.png';
import { Link } from 'react-router-dom';

function ServicesComponents() {
    const data = [
        { image: Service1, heading: 'Website design and development', list: ['Sitemap', 'Data Migration', 'UX/UI'] },
        { image: Service2, heading: 'Publishing services', list: ['English language editing', 'Types editing', 'Plagiarism checks', 'English language checks'] },
        { image: Service3, heading: 'Data conversions', list: ['Kindle', 'XML', 'HTML'] },
        { image: Service4, heading: 'Software Solutions', list: ['ERP solutions', 'Lab management', 'Conference Management System', 'API integration'] },
        { image: Service5, heading: 'Payment Solutions', list: ['Payment links', 'Payment integration', 'PayPal links', 'PayPal integrations'] },
        { image: Service6, heading: 'Security', list: ['Eurekaselect', 'Access control systems'] },
    ];
    return (
        <div className="services pt-5">
            <div className="container-xl">
                <div className="col-12 col-md-9 m-auto text-white text-center">
                    <h3 className="service-heading">Services</h3>
                    <p className="service-para mb-4">For your assistance, ITS offers a range of affordable and quick-to-subscribe solutions
                        through which you can manage your work with ease.</p>
                    <Link className='read-more' to='/services'>Read more</Link>
                </div>
                <div className="row pt-5">
                    {data.map((item, index) => {
                        return <div key={index} className="col-md-4 mb-5" data-aos="fade-up"
                            data-aos-duration="1000">
                            <div className="card card-rounded h-100">
                                <img src={item.image} alt={item.heading} title={item.heading} />
                                <div className="card-body px-4 py-5">
                                    <h4 className='service-type mb-4'>{item.heading}</h4>
                                    <ul className='list-style-none'>
                                        {item.list.map((v, i) => {
                                            return <li className='service-list' key={i}><CheckIcon className='list-check' /> {v}</li>
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default ServicesComponents;