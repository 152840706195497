import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";

// import Routing
import AppRouter from './Config/AppRouter';

// import Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

// import Main css File
import './App.css'

function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <AppRouter />
  )
}

export default App;