import React from 'react';
import Logo from '../assets/images/ITS-logo.png';
import { Link } from 'react-router-dom';
import '../assets/css/footer.css';

function Footer() {
    return (
        <footer className='footer bg-myprimary text-white pt-5'>
            <div className='container-xl mb-3'>
                <div className="text-center">
                    <Link to="/">
                        <img src={Logo} alt='its-logo' title='its-logo' className='logo' />
                    </Link>
                </div>
            </div>
            <div className='my_divider'></div>
            <div className='main_footer'>
                <div className='container-xl'>
                    <div className='row pt-5'>
                        <div className="col-md-5 mb-5">
                            <div className="footer_content">
                                <h4>Information Technology Services</h4>
                                <p>Information Technology Services is an independent software vendor that offers the latest and most advanced technology solutions. These solutions include custom applications and contract software.</p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-5">
                            <div className="footer_content">
                                <h4>Quick Links</h4>
                                <div className="footer-link">
                                    <Link to='/'>Home</Link>
                                    <Link to='/about-us'>About Us</Link>
                                    <Link to='/services'>Services</Link>
                                    <Link to='/projects'>Projects</Link>
                                    <Link to='/career'>Career</Link>
                                    <Link to='/contact-us'>Contact Us</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-5">
                            <div className="footer_content">
                                <h4>Contact Info</h4>
                                <p>Address: C-32, Block -1(A), <br /> Information Technology Services Building, <br /> Gulistan-e-Johar, <br /> Karachi, Pakistan.</p>
                                <p><b>Contact :</b> +92-3333977095</p>
                                <p><b>Email :</b></p>
                                <p className='small'>queries@informationtechnologyservices.com.pk</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;