import React from 'react';
import AboutImage from '../assets/images/About-Us.jpg';
import { Link } from 'react-router-dom';

function AboutComponents() {
    return (
        <div className="about">
            <div className="container-xl">
                <div className="row">
                    <div className="col-md-6 d-flex align-items-center" data-aos="fade-right">
                        <img src={AboutImage} alt="about-us" title='About Us' className='img-fluid' />
                    </div>
                    <div className="col-md-6 p-4 pb-5 p-md-5" data-aos="fade-left"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h3 className='heading mb-3'>Know Us More...</h3>
                        <p className='discription mb-5'>Information Technology Services is an independent software vendor that offers the latest and most advanced technology solutions. These solutions include custom applications and contract software. Here at I.T.S, significant importance is given to solution services, embedded systems, wireless devices, windows based software applications and much more.</p>
                        <Link className='about-link py-3 px-5' to='/about-us'>About Us</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutComponents;