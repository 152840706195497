import React from 'react';
import Slider1 from '../assets/images/Project-carousel/Eurekaselect.jpg';
import Slider2 from '../assets/images/Project-carousel/Global-Biotechnology-Congress-2017.jpg';
import Slider3 from '../assets/images/Project-carousel/Institute-of-Industrial-Electronics-Engineering.jpg';
import Slider4 from '../assets/images/Project-carousel/Eureka-Science.jpg';

function ProjectCarousel() {
    return (
        <div className='d-flex align-items-center h-100'>
            <div>
                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src={Slider1} className="d-block w-100" alt="project-1" />
                        </div>
                        <div className="carousel-item">
                            <img src={Slider2} className="d-block w-100" alt="project-2" />
                        </div>
                        <div className="carousel-item">
                            <img src={Slider3} className="d-block w-100" alt="project-3" />
                        </div>
                        <div className="carousel-item">
                            <img src={Slider4} className="d-block w-100" alt="project-4" />
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true" />
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true" />
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                <div className="row">
                    <div className="col-3 px-0" role="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={0} aria-current="true" aria-label="Slide 1">
                        <img src={Slider1} alt="slider1" className='img-fluid' />
                    </div>
                    <div className="col-3 px-0" role="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={1} aria-current="true" aria-label="Slide 2">
                        <img src={Slider2} alt="slider2" className='img-fluid' />
                    </div>
                    <div className="col-3 px-0" role="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={2} aria-current="true" aria-label="Slide 3">
                        <img src={Slider3} alt="slider3" className='img-fluid' />
                    </div>
                    <div className="col-3 px-0" role="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={3} aria-current="true" aria-label="Slide 4">
                        <img src={Slider4} alt="slider4" className='img-fluid' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectCarousel;