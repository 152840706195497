import React, { useEffect, useState } from 'react';

// import Components
import Loader from '../Components/Loader';
import ChangePageTitle from '../Components/ChangePageTitle';
import GotoTop from '../Components/GotoTop';
import TopHeader from '../Components/TopHeader';
import MainMenu from '../Components/MainMenu';
import HeroSection from '../Components/HeroSection';
import TopAbout from '../Components/TopAbout';
import AboutComponents from '../Components/AboutComponents';
import ServicesComponents from '../Components/ServicesComponents';
import OurProjects from '../Components/OurProjects';
import WorkingProcess from '../Components/WorkingProcess';
import GlimpseOfLife from '../Components/GlimpseOfLife';
import OurPartners from '../Components/OurPartners';
import Footer from '../Components/Footer';

function Home() {
    const [isLoader, setisLoader] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setisLoader(false);
        }, 1000);
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            {isLoader ? <Loader /> : null}
            <ChangePageTitle pageTitle="Information Technology Services (I.T.S) :: Home" />
            <GotoTop />
            <TopHeader />
            <MainMenu />
            <HeroSection />
            <TopAbout />
            <AboutComponents />
            <ServicesComponents />
            <OurProjects />
            <WorkingProcess />
            <GlimpseOfLife />
            <OurPartners />
            <Footer />
        </>
    )
}

export default Home;