import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';

function AboutMission() {
    return (
        <div className="container-xl">
            <div className="row">
                <div className="col-md-6 color-myprimary p-5 shadow flex-center" data-aos="fade-left" data-aos-animation="4000">
                    <div className='text-center'>
                        <h1>Mission</h1>
                        <p className='text-justify'>Diagnose and Cure impediments to the success of our customer´s organizations by developing innovative solutions and products that enhance business agility enabling them to outperform their competition, overcome their challenges and effectively utilize opportunities to excel and grow.</p>
                    </div>
                </div>
                <div className="col-md-6 bg-myprimary p-5 shadow flex-center" data-aos="fade-right" data-aos-animation="4000">
                    <FontAwesomeIcon icon={faBullseye} className='bigIcon' />
                </div>
            </div>
        </div>
    )
}

export default AboutMission;