import React, { useState, useEffect } from 'react';
import Loader from '../Components/Loader';
import ChangePageTitle from '../Components/ChangePageTitle';
import GotoTop from '../Components/GotoTop';
import TopHeader from '../Components/TopHeader';
import MainMenu from '../Components/MainMenu';
import ItsLifeStyle from '../Components/ItsLifeStyle';
import CoreValues from '../Components/CoreValues';
import OurBenefits from '../Components/OurBenefits';
import CareerIts from '../Components/CareerIts';
import Footer from '../Components/Footer';

function Career() {
  const [isLoader, setisLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setisLoader(false);
    }, 1000);
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      {isLoader ? <Loader /> : null}
      <ChangePageTitle pageTitle="Information Technology Services (I.T.S) :: Career" />
      <GotoTop />
      <TopHeader />
      <MainMenu />
      <ItsLifeStyle />
      <CoreValues />
      <OurBenefits />
      <CareerIts />
      <Footer />
    </>

  )
}

export default Career;