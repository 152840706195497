import React from 'react';
import '../assets/css/Loader.css'
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }),
);

function Loader() {
    const classes = useStyles();

    return (
        <div>
            <Backdrop className={classes.backdrop} open={true}>
                <div className="loader3">
                    <span></span>
                    <span></span>
                </div>
            </Backdrop>
        </div>
    );
}

export default Loader;