import React, { useState, useEffect } from 'react';
import Loader from '../Components/Loader';
import ChangePageTitle from '../Components/ChangePageTitle';
import GotoTop from '../Components/GotoTop';
import TopHeader from '../Components/TopHeader';
import MainMenu from '../Components/MainMenu';
import AboutITS from '../Components/AboutITS';
import AboutVision from '../Components/AboutVision';
import AboutMission from '../Components/AboutMission';
import Footer from '../Components/Footer';

function AboutUs() {
  const [isLoader, setisLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setisLoader(false);
    }, 1000);
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      {isLoader ? <Loader /> : null}
      <ChangePageTitle pageTitle="Information Technology Services (I.T.S) :: About" />
      <GotoTop />
      <TopHeader />
      <MainMenu />
      <AboutITS />
      <AboutVision />
      <AboutMission />
      <Footer />
    </>
  )
}

export default AboutUs;