import React from 'react';
import { Link } from 'react-router-dom';
import ProjectCarousel from './ProjectCarousel';

function OurProjects() {
    return (
        <div className="our-projects bg-light pt-5">
            <div className="container-xl">
                <div className='col-12 col-md-9 m-auto text-center'>
                    <h2 className='project-heading mb-3'>Our Projects</h2>
                    <p>We, here at I.T.S, never compromise over quality. With our significant work ethic and approachable plans, I.T.S doesn’t look back at satisfying clients.</p>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-5">
                        <ProjectCarousel />
                    </div>
                    <div className="col-md-6 mb-5 p-4 p-md-5" data-aos="fade-left"
                        data-aos-duration="2000">
                        <h2 className='mb-3'>Our Successful Projects</h2>
                        <p className='mb-5 project-para'>I.T.S’s expertise strives to excel. Our performances in our latest software solution projects have distinctly stood out. Our successful projects include PCSIR’s Centralized Training System, IIEE (Institute of Industrial Electronics and Engineering), Drug Discovery and Therapy World Congress and more. It has been a joint effort of our competent team and our clients that I.T.S has been able to develop high quality technology solutions and we aim to keep working harder to provide you quality services.</p>
                        <Link className='about-link py-3 px-5' to='/projects'>About Us</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurProjects;